<template>
    <div>
        <pre>{{ propsToBind }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsSyneriseConfig',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        apiKey: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        trackerKey: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        trackerDomain: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        scriptUrl: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webModPdp1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webModPdp2: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webModPdpUnavbl1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webModPrecart1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webModCart1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webModPdpSimilar: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webPdpProductNotification: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webModCategory1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webModSearchResults1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webModErrorPage1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webModEmptyCart1: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    apiKey: '',
                    trackerKey: '',
                    trackerDomain: '',
                    scriptUrl: '',
                    webModPdp1: '',
                    webModPdp2: '',
                    webModPdpUnavbl1: '',
                    webModPrecart1: '',
                    webModCart1: '',
                    webModPdpSimilar: '',
                    webPdpProductNotification: '',
                    webModCategory1: '',
                    webModSearchResults1: '',
                    webModErrorPage1: '',
                    webModEmptyCart1: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                scriptUrl: {
                    isUrl: true,
                },

                trackerDomain: {
                    isUrl: true,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
